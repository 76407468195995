import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Client, MqttProtocolToString, MqttVersionToString } from '../types'
import { intlFormat, intlFormatDistance } from 'date-fns'
import TrafficChart from '../components/TrafficChart'
import MqttClientEditModal from './MqttClientEditModal'
import SubscriptionsModal from '../components/SubscriptionsModal'
import MqttClientDeleteModal from './MqttClientDeleteModal'
import { apiGet } from '../fetch'
import SubscriptionsTable from '../components/SubscriptionsTable'

function RelativeAndAbsoluteTime({ time }: { time: Date }) {
  if (time.valueOf() === -62135596800000) {
    return (
      <>
        N/A
        <br />
        <span className="text-muted small">Never</span>
      </>
    )
  }
  return (
    <>
      {intlFormatDistance(time, new Date())}
      <br />
      <span className="text-muted small">
        {intlFormat(time, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })}
      </span>
    </>
  )
}

function parseClientJson(data: Client): Client {
  data.connection_info.LastConnectTime = new Date(data.connection_info.LastConnectTime)
  data.connection_info.LastDisconnectTime = new Date(data.connection_info.LastDisconnectTime)
  data.connection_info.LastConnectAttemptTime = new Date(data.connection_info.LastConnectAttemptTime)
  data.connection_info.LastTxTime = new Date(data.connection_info.LastTxTime)
  data.connection_info.LastRxTime = new Date(data.connection_info.LastRxTime)
  data.connection_info.LastPingRequest = new Date(data.connection_info.LastPingRequest)
  data.connection_info.LastPingResponse = new Date(data.connection_info.LastPingResponse)
  return data
}

export default function MqttClient() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [client, setData] = useState<Client | null>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false)

  useEffect(() => {
    document.title = id + ' - MQTT Clients - Avoguard'
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  function refresh() {
    apiGet(`/client/${id}`).then((data) => {
      setData(parseClientJson(data))
    })
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/mqtt' }}>
            MQTT Clients
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/mqtt/${id}` }} active>
            {id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="align-self-center">
          <Link to={`/mqtt/${id}/events`} className="btn btn-outline-primary">
            Event tap
          </Link>
          <button type="button" className="btn btn-info" onClick={refresh}>
            Refresh
          </button>
          <button type="button" className="btn btn-danger" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
          <button type="button" className="btn btn-primary" onClick={() => setShowSubscriptionsModal(true)}>
            Edit subscriptions
          </button>
          <button type="button" className="btn btn-primary" onClick={() => setShowEditModal(true)}>
            <svg className="bi" width="18" height="18">
              <use xlinkHref="#mqtt-clients" />
            </svg>{' '}
            Edit client
          </button>
        </div>
      </header>
      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <svg className="bi mt-2" width="32" height="32" role="img">
                <use xlinkHref={'#mqtt-clients'} />
              </svg>{' '}
              <Link to="/mqtt">MQTT Clients</Link> /{' '}
            </span>{' '}
            {id}
          </h1>
          <p className="text-muted">
            An MQTT Client is a bi-directional communication channel to an MQTT broker. It can be used to send and
            receive MQTT packets.
          </p>
        </div>
      </div>
      <TrafficChart component="client" id={id!} />
      <div className="row mb-3">
        <div className="col">
          <div className="header">
            <h2>Subscriptions</h2>
          </div>
          <div className="panel">
            {client !== null && <SubscriptionsTable subscriptions={client.subscriptions} mode="client" />}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-6 col-xl-4">
          <div className="header">
            <h2>Configuration</h2>
          </div>
          <div className="panel">
            {client !== null ? (
              <table className="details">
                {client !== null ? (
                  <tbody>
                    <tr>
                      <td>Client&nbsp;ID</td>
                      <td>
                        <code>{client.id}</code>
                      </td>
                    </tr>
                    <tr>
                      <td>Target Broker</td>
                      <td>
                        <code>
                          {client.host}:{client.port}
                        </code>
                      </td>
                    </tr>
                    <tr>
                      <td>Protocol</td>
                      <td>{MqttProtocolToString[client.protocol]}</td>
                    </tr>
                    <tr>
                      <td>MQTT Protocol Version</td>
                      <td>{MqttVersionToString[client.version]}</td>
                    </tr>
                    <tr>
                      <td>Retries</td>
                      <td>
                        {client.retry_count} attempts, {client.retry_interval_s} second backoff
                      </td>
                    </tr>
                    <tr>
                      <td>Keep Alive Interval</td>
                      <td>{client.keepalive_s === 0 ? 'disabled' : `${client.keepalive_s}s`}</td>
                    </tr>
                  </tbody>
                ) : (
                  ''
                )}
              </table>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>

        <div className="col col-md-6 col-xl-4">
          <div className="header">
            <h2>Connection</h2>
          </div>
          <div className="panel">
            {client !== null ? (
              <table className="details">
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      {client.connection_info.IsConnected ? (
                        <Badge bg="success">CONNECTED</Badge>
                      ) : (
                        <Badge bg="warning">DISCONNECTED</Badge>
                      )}
                      <span className="text-muted small ps-2">
                        {client.connection_info.IsConnected
                          ? client.connection_info.LastConnectTime.valueOf() === -62135596800000
                            ? 'never disconnected'
                            : intlFormatDistance(client.connection_info.LastConnectTime, new Date())
                          : client.connection_info.LastDisconnectTime.valueOf() === -62135596800000
                          ? 'never connected'
                          : intlFormatDistance(client.connection_info.LastDisconnectTime, new Date())}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Ping Latency</td>
                    <td>
                      {client.connection_info.LastPingLatency === 0
                        ? 'N/A'
                        : `${(client.connection_info.LastPingLatency / 1000000).toFixed(1)} ms`}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2} className="p-2 text-muted pt-4">
                      Connection Timestamps
                    </th>
                  </tr>
                  <tr>
                    <td>{client.connection_info.IsConnected ? 'Connected' : 'Disconnected'} since</td>
                    <td>
                      <RelativeAndAbsoluteTime
                        time={
                          client.connection_info.IsConnected
                            ? client.connection_info.LastConnectTime
                            : client.connection_info.LastDisconnectTime
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Last {client.connection_info.IsConnected ? 'disconnected' : 'connected'}</td>
                    <td>
                      {client.connection_info.IsConnected &&
                        (client.connection_info.LastDisconnectTime.getUTCFullYear() === 1 ? (
                          'N/A'
                        ) : (
                          <RelativeAndAbsoluteTime time={client.connection_info.LastDisconnectTime} />
                        ))}
                      {!client.connection_info.IsConnected &&
                        (client.connection_info.LastConnectTime.getUTCFullYear() === 1 ? (
                          'N/A'
                        ) : (
                          <RelativeAndAbsoluteTime time={client.connection_info.LastConnectTime} />
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Last connection attempt</td>
                    <td>
                      <RelativeAndAbsoluteTime time={client.connection_info.LastConnectAttemptTime} />
                    </td>
                  </tr>
                  <tr>
                    <td>Last connection error</td>
                    {client.connection_info.LastError === '' ? (
                      <td>No error</td>
                    ) : (
                      <td className="text-danger">{client.connection_info.LastError}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>

        <div className="col col-md-6 col-xl-4">
          <div className="header">
            <h2>Data timestamps</h2>
          </div>
          <div className="panel">
            {client !== null ? (
              <table className="details">
                <tbody>
                  <tr>
                    <th colSpan={2} className="p-2 text-muted pt-4">
                      Data traffic
                    </th>
                  </tr>
                  <tr>
                    <td>Last TX</td>
                    <td>
                      <RelativeAndAbsoluteTime time={client.connection_info.LastTxTime} />
                    </td>
                  </tr>
                  <tr>
                    <td>Last RX</td>
                    <td>
                      <RelativeAndAbsoluteTime time={client.connection_info.LastRxTime} />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2} className="p-2 text-muted pt-4">
                      Ping traffic
                    </th>
                  </tr>
                  <tr>
                    <td>Last Ping</td>
                    <td>
                      <RelativeAndAbsoluteTime time={client.connection_info.LastPingRequest} />
                    </td>
                  </tr>
                  <tr>
                    <td>Last Pong</td>
                    <td>
                      <RelativeAndAbsoluteTime time={client.connection_info.LastPingResponse} />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
      </div>
      {client !== null && (
        <>
          <MqttClientDeleteModal
            show={showDeleteModal}
            clientId={client.id}
            onHide={() => setShowDeleteModal(false)}
            onDeleted={() => navigate('/mqtt')}
          />
          <MqttClientEditModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            client={client}
            onSubmit={() => {
              setShowEditModal(false)
              refresh()
            }}
          />
          <SubscriptionsModal
            subscriptions={client.subscriptions}
            clientId={client!.id}
            show={showSubscriptionsModal}
            onHide={() => setShowSubscriptionsModal(false)}
            onSubmit={() => {
              setShowSubscriptionsModal(false)
              refresh()
            }}
          />
        </>
      )}
    </Container>
  )
}
