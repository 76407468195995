import { useEffect, useState } from 'react'
import { Container, Breadcrumb, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MqttClientListItem } from '../types'
import { intlFormat, intlFormatDistance } from 'date-fns'
import { apiGet } from '../fetch'
import { toast } from 'react-toastify'

export default function MqttClients() {
  const [data, setData] = useState<MqttClientListItem[] | null>(null)

  useEffect(() => {
    document.title = 'MQTT Clients - Avoguard'

    apiGet('/clients')
      .then((data) =>
        setData(
          data.map((client: MqttClientListItem) => {
            client.last_rx_time = new Date(client.last_rx_time)
            client.last_connect_time = new Date(client.last_connect_time)
            client.last_disconnect_time = new Date(client.last_disconnect_time)
            return client
          })
        )
      )
      .catch((err) => toast(`Error fetching MQTT clients, ${err}`, { type: 'error' }))
  }, [])

  function renderTableData(data: MqttClientListItem[] | null) {
    if (data === null) {
      return (
        <tr>
          <td colSpan={6}>Loading MQTT client list ...</td>
        </tr>
      )
    }
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={6}>No MQTT clients found</td>
        </tr>
      )
    }

    return data.map((client) => (
      <tr key={client.id}>
        <td></td>
        <td>
          <Link to={`/mqtt/${client.id}`}>{client.id}</Link>
        </td>
        <td>
          <code>
            {client.host}:{client.port}
          </code>
        </td>
        <td>{client.subscription_count}</td>
        <td>
          {client.is_connected ? (
            <>
              <Badge bg="success">online</Badge>{' '}
              <span className="text-muted small ps-1">
                {client.last_connect_time.valueOf() === -62135596800000
                  ? 'never connected'
                  : 'since ' + intlFormatDistance(client.last_connect_time, new Date())}
              </span>
            </>
          ) : (
            <>
              <Badge bg="warning">offline</Badge>
              <span className="text-muted small ps-1">
                {client.last_disconnect_time.valueOf() === -62135596800000
                  ? 'never connected'
                  : 'since ' + intlFormatDistance(client.last_disconnect_time, new Date())}
              </span>
            </>
          )}
        </td>
        <td>
          {isNaN(client.last_rx_time.valueOf()) || client.last_rx_time.valueOf() === -62135596800000 ? (
            'never'
          ) : (
            <span>
              {intlFormatDistance(client.last_rx_time, new Date())}{' '}
              <span className="text-muted small ps-1">
                {intlFormat(client.last_rx_time, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
              </span>
            </span>
          )}
        </td>
      </tr>
    ))
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/mqtt' }} active>
            MQTT Clients
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="toolbar">
          <Link to="/mqtt-new" className="btn btn-primary btn-avgrd">
            <svg className="bi" width="18" height="18">
              <use xlinkHref="#mqtt-clients" />
            </svg>
            &nbsp; Add MQTT Client
          </Link>
        </div>
      </header>
      <h1>
        <svg className="bi mt-2" width="32" height="32" role="img">
          <use xlinkHref={'#mqtt-clients'} />
        </svg>{' '}
        MQTT Clients
      </h1>
      <p className="text-muted">
        MQTT Clients are a way to send data to a MQTT broker. This is useful for sending data to a database or a web
        service.
      </p>

      <div className="panel">
        <div className="table-toolbar">
          {/* <nav className="nav">
            <a href="#" className="nav-link active">
              All <span className="badge bg-secondary">12</span>
            </a>
            <a href="#" className="nav-link">
              Online <span className="badge bg-secondary">10</span>
            </a>
            <a href="#" className="nav-link">
              Offline <span className="badge bg-secondary">2</span>
            </a>
          </nav> */}
          {/* <div className="search row g-3 ms-auto">
            <div className="col-auto">
              <label className="col-form-label">Search</label>
            </div>
            <div className="col-auto">
              <input type="text" className="form-control" placeholder="search clients by ID"></input>
            </div>
          </div> */}
        </div>

        <table>
          <thead>
            <tr>
              <th></th>
              <th>Client ID</th>
              <th>Target broker</th>
              <th>Subscriptions</th>
              <th>Status</th>
              <th>Last seen</th>
            </tr>
          </thead>
          <tbody>{renderTableData(data)}</tbody>
        </table>
        <div className="table-bottombar"></div>
      </div>
    </Container>
  )
}
