import { useState } from 'react'
import { Client } from '../types'
import { Collapse } from 'react-bootstrap'

const defaults = {
  port: 1883,
  keepalive_s: 300,
  retry_count: 2,
  retry_interval_s: 5,
}

export default function MqttClientForm(props: { client?: Client }) {
  const [clientId, setClientId] = useState<string>('')
  const [openAdvanced, setOpenAdvanced] = useState<boolean>(false)
  const [port, setPort] = useState<number>(props.client?.port || defaults.port)

  function onHostChange(e: React.ChangeEvent<HTMLInputElement>) {
    setClientId(e.target.value)
  }

  function onClientIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setClientId(e.target.value)
  }

  function onProtocolChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setPort(e.target.value === '2' ? 8883 : 1883)
  }

  function onPortChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPort(parseInt(e.target.value, 10))
  }

  return (
    <>
      <div className="mb-3 row">
        <div className="col-6">
          <label htmlFor="brokerVersion" className="form-label">
            Protocol
          </label>
          <select
            className="form-select"
            id="brokerVersion"
            defaultValue={props.client?.protocol}
            name="protocol"
            onChange={onProtocolChange}
          >
            <option value="0">MQTT (no auth)</option>
            <option value="1" disabled>
              MQTT (password auth)
            </option>
            <option value="2" disabled>
              MQTT+TLS (no auth)
            </option>
            <option value="3" disabled>
              MQTT+TLS (PSK)
            </option>
            <option value="4" disabled>
              MQTT+TLS (Certificate)
            </option>
          </select>
        </div>
        <div className="col-6">
          <label htmlFor="brokerVersion" className="form-label">
            MQTT protocol version
          </label>
          <select className="form-select" id="brokerVersion" defaultValue={props.client?.version} name="version">
            <option value="4">3.1.1</option>
            <option value="5">5.0</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-8">
          <label htmlFor="brokerHost" className="form-label">
            Broker host
          </label>
          <input
            type="text"
            className="form-control"
            id="brokerHost"
            defaultValue={props.client?.host}
            name="host"
            onChange={props.client === undefined ? onHostChange : undefined}
          />
        </div>
        <div className="col-4">
          <label htmlFor="brokerPort" className="form-label">
            Broker port
          </label>
          <input
            type="text"
            className="form-control"
            id="brokerPort"
            value={port}
            onChange={onPortChange}
            name="port"
          />
        </div>
      </div>
      {props.client === undefined && (
        <div className="mb-3">
          <label htmlFor="clientId" className="form-label">
            Client ID
          </label>
          <input
            type="text"
            className="form-control"
            id="clientId"
            name="id"
            required
            value={clientId}
            onChange={onClientIdChange}
          />
          <p>
            <small className="text-muted">
              Make sure to choose a unique ID. It cannot be changed once the MQTT client is added.
            </small>
          </p>
        </div>
      )}

      <div>
        <p>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => {
              setOpenAdvanced(!openAdvanced)
            }}
          >
            Advanced settings
          </button>
        </p>
        <Collapse in={openAdvanced}>
          <div>
            <div className="mb-3 row">
              <div className={props.client === undefined ? 'col-6' : 'col-8'}>
                <label htmlFor="brokerKeepAlive" className="form-label">
                  Keep alive interval <span className="small">[seconds]</span>{' '}
                  <span className="small text-muted ms-2">0 = disabled</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="brokerKeepAlive"
                  defaultValue={props.client?.keepalive_s || defaults.keepalive_s}
                  name="keepalive_s"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <label htmlFor="brokerRetryMax" className="form-label">
                  Retry attempts
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="brokerRetryMax"
                  defaultValue={props.client?.retry_count || defaults.retry_count}
                  name="retry_count"
                />
              </div>
              <div className="col">
                <label htmlFor="brokerRetryInterval" className="form-label">
                  Retry backoff <span className="small">[seconds]</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="brokerRetryInterval"
                  defaultValue={props.client?.retry_interval_s || defaults.retry_interval_s}
                  name="retry_interval_s"
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  )
}
