export type Backend = {
  id: string
  host: string
  port: number
  protocol: number
  method: number
  content_type: string
  path_prefix: string
  retry_interval_s: number
  retry_count: number
  timeout_s: number

  last_response_code: number
  subscriptions: Subscription[]
}

export type HttpBackendListItem = {
  id: string
  host: string
  port: number
  protocol: number
  method: number
  content_type: string
  path_prefix: string
  retry_interval_s: number
  retry_max: number
  timeout_s: number

  subscription_count: number
  last_response_code: number
}

type ConnectionInfo = {
  IsConnected: boolean
  LastConnectAttemptTime: Date
  LastConnectTime: Date
  LastDisconnectTime: Date

  LastError: string
  LastErrorTime: Date

  LastTxTime: Date
  LastRxTime: Date
  LastPingRequest: Date
  LastPingResponse: Date
  LastPingLatency: number
}

export type MqttClientListItem = {
  row_id: number
  id: string
  host: string
  port: number
  version: number
  subscription_count: number
  is_connected: boolean
  last_connect_time: Date
  last_disconnect_time: Date
  last_rx_time: Date
}

export type Client = {
  id: string

  host: string
  port: number
  protocol: number
  version: number
  keepalive_s: number
  retry_count: number
  retry_interval_s: number

  subscriptions: Subscription[]
  connection_info: ConnectionInfo

  TrafficStats: TrafficStats[]
}

export type Subscription = {
  id: string
  backend_id: string
  client_id: string
  filter: string
  qos: number
}

export type TrafficStats = {
  Timestamp: Date
  name: string // assigned on frontend
  TxBytes: number
  RxBytes: number
  TxPackets: number
  RxPackets: number
}

export type MqttClient = {
  ConnectPayload: {
    ClientId: string
  }
  RemoteAddr: {
    IP: string
    Port: number
  }
  ConnectionInfo: ConnectionInfo
}

export type ServerConfig = {
  Host: string
  Port: number
  Path: string
  Enabled: boolean
}

export const MqttVersionToString: { [key: number]: string } = {
  3: '3.1',
  4: '3.1.1',
  5: '5.0',
}

export const MqttProtocolToString: { [key: number]: string } = {
  0: 'MQTT (no auth)',
  1: 'MQTT (password auth)',
  2: 'MQTT+TLS (no auth)',
  3: 'MQTT+TLS (PSK)',
  4: 'MQTT+TLS (Certificate)',
}

export type Session = {
  user: {
    uuid: string
    email: string
    username: string
    first_name: string
    last_name: string
    nickname: string
    created_at: Date
  }
  organization: {
    uuid: string
    name: string
  }
  expires_at: Date
}

export const HttpProtocolToString: { [key: number]: string } = {
  0: 'HTTP',
  1: 'HTTPS',
}

export const HttpMethodToString: { [key: number]: string } = {
  0: 'POST',
  1: 'PUT',
  2: 'GET',
}
