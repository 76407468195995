import { Breadcrumb, Container } from 'react-bootstrap'
import { useSession } from './SessionContext'
import { Link } from 'react-router-dom'

export default function Profile() {
  const { session } = useSession()

  return (
    <Container>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/profile' }} active>
            Profile
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>
      <div className="row">
        <div className="col">
          <h1>
            Profile <span className="text-muted"> / {session?.user.username}</span>
          </h1>
          <p className="text-muted">
            Information about your Avoguard user account and organization. This page is only visible to you.
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-6">
          <div className="header">
            <h2>User</h2>
          </div>
          <div className="panel">
            <table>
              <tbody>
                <tr>
                  <th>User UUID</th>
                  <td>
                    <code>{session?.user.uuid}</code>
                  </td>
                </tr>
                <tr>
                  <th>Username</th>
                  <td>{session?.user.username}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{session?.user.email}</td>
                </tr>
                <tr>
                  <th>First Name</th>
                  <td>{session?.user.first_name}</td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>{session?.user.last_name}</td>
                </tr>
                <tr>
                  <th>Nickname</th>
                  <td>{session?.user.nickname}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col col-6">
          <div className="header">
            <h2>Organization</h2>
          </div>
          <div className="panel">
            <table>
              <tbody>
                <tr>
                  <th>Organization UUID</th>
                  <td>
                    <code>{session?.organization.uuid}</code>
                  </td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{session?.organization.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  )
}
