import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { apiDelete } from '../fetch'

export default function HttpBackendDeleteModal(props: {
  show: boolean
  backendId: string
  onHide: () => void
  onDeleted: () => void
}) {
  function onDeleteSubmit() {
    apiDelete('/backend/' + props.backendId)
      .then(() => {
        toast(`HTTP backend '${props.backendId}' deleted`, { type: 'success' })
        props.onHide()
        props.onDeleted()
      })
      .catch((err: Error) => {
        toast(`Error deleting backend, ${err.message}`, { type: 'error' })
      })
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete HTTP Backend &quot;{props.backendId}&quot;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the <code>{props.backendId}</code> HTTP backend?
        </p>
        <p>
          <strong>This action cannot be undone.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" className="btn btn-secondary" onClick={props.onHide}>
          Cancel
        </button>
        <button type="submit" className="btn btn-danger" onClick={() => onDeleteSubmit()}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}
