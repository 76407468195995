import { useEffect, useState } from 'react'
import { apiGet } from '../fetch'

export default function SubscriptionForm() {
  const [backendIds, setBackendIds] = useState<string[]>([])
  const [clientIds, setClientIds] = useState<string[]>([])

  useEffect(() => {
    apiGet('/backends/ids').then((data) => {
      setBackendIds(data)
    })
    apiGet('/clients/ids').then((data) => {
      setClientIds(data)
    })
  }, [])

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-12">
          <label htmlFor="sub-client-id" className="form-label">
            Source client ID
          </label>
          <select className="form-select" name="client_id" disabled={clientIds.length === 0} required>
            {clientIds.length === 0 && (
              <option disabled selected>
                No MQTT clients found. Create an MQTT client first.
              </option>
            )}
            {clientIds.map((clientId) => (
              <option key={clientId} value={clientId}>
                {clientId}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-8">
          <label htmlFor="sub-topic-filter" className="form-label">
            Topic filter
          </label>
          <input type="text" className="form-control" id="sub-topic-filter" name="filter" required />
        </div>
        <div className="col-4">
          <label htmlFor="sub-qos" className="form-label">
            Quality of service (QoS)
          </label>
          <select className="form-select" id="sub-qos" name="qos" defaultValue="0" required>
            <option value="0">QoS 0</option>
            <option value="1">QoS 1</option>
            <option value="2">QoS 2</option>
          </select>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-12">
          <label htmlFor="sub-backend-id" className="form-label">
            Destination backend ID
          </label>
          <select className="form-select" name="backend_id" disabled={backendIds.length === 0} required>
            {backendIds.length === 0 && (
              <option disabled selected>
                No HTTP backends found. Create a HTTP backend first.
              </option>
            )}
            {backendIds.map((backendId) => (
              <option key={backendId} value={backendId}>
                {backendId}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}
