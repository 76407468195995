import { Link } from 'react-router-dom'
import { Subscription } from '../types'

export default function SubscriptionsTable(props: { mode: 'client' | 'backend'; subscriptions: Subscription[] }) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th></th>
            {props.mode === 'backend' && <th>Source MQTT Client ID</th>}
            <th>Topic Filter</th>
            <th>Quality of Service</th>
            {props.mode === 'client' && <th>Destination HTTP Backend ID</th>}
          </tr>
        </thead>
        <tbody>
          {props.subscriptions.length === 0 && (
            <tr>
              <td colSpan={4} className="text-center text-muted">
                No subscriptions for this {props.mode === 'backend' ? 'HTTP backend' : 'MQTT client'}
              </td>
            </tr>
          )}
          {props.subscriptions.map((s, index) => (
            <tr key={index}>
              <td></td>
              {props.mode === 'backend' && <td>{s.client_id}</td>}
              <td>
                <Link to={`/sub/${s.id}`}>
                  <code>{s.filter}</code>
                </Link>
              </td>
              <td>{s.qos}</td>
              {props.mode === 'client' && (
                <td>
                  <Link to={`/http/${s.backend_id}`}>{s.backend_id}</Link>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
