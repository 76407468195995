import { toast } from 'react-toastify'
import style from './Login.module.css'
import { API_URL } from '../constants'
import { Session } from '../types'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../SessionContext'
import { generateBackgroundItems } from './background'

export default function Login() {
  const { setSession } = useSession()
  const navigate = useNavigate()
  const items = generateBackgroundItems()

  document.title = 'Login - Avoguard'

  async function onLoginSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const data = Object.fromEntries(formData.entries())

    const response = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response.body !== null) {
        const value = await response.body
          .getReader()
          .read()
          .then((result) => new TextDecoder('ascii').decode(result.value))
        toast(`Login error, ${value}`, { type: 'error' })
      } else {
        toast(`Login error, ${response.statusText}`, { type: 'error' })
      }
      return
    }

    const session: Session = await response.json()
    localStorage.setItem('session', JSON.stringify(session))
    setSession(session)
    navigate('/')
  }

  return (
    <>
      <div id="background" className={style.background}>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              width: item.width,
              height: item.height,
              backgroundColor: item.backgroundColor,
              left: item.left,
              top: item.top,
            }}
          ></div>
        ))}
      </div>
      <div className={style.login}>
        <form onSubmit={onLoginSubmit}>
          <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.733333 67.733333" width="220" height="220">
              <g>
                <path
                  d="m 102.34382,171.75629 c 36.21262,36.20722 74.43292,46.18244 74.43292,46.18244 C 159.60579,235.43629 144.69547,244.6928 128,245.08008 110.33072,244.67021 94.660896,234.32629 76.191406,214.78906 56.598918,194.06391 32.456303,165.62417 22.119842,116.33013 45.165474,105.29007 50.123918,104.35417 72.880012,99.045397 71.729756,130.19305 86.985513,155.94438 102.34382,171.75629"
                  transform="scale(0.26458333)"
                />
                <path
                  d="m 142.14786,181.14289 c 37.53012,-24.43594 40.11255,-70.00061 40.11255,-70.00061 0,0 5.41679,-52.948059 -1.41088,-93.454563 17.06243,2.569476 34.02341,6.999081 43.93563,14.68533 13.2158,10.247947 17.28142,16.092333 12.78515,60.015625 -5.71619,55.840458 -27.96344,88.901418 -48.48486,112.264668 -14.22392,-5.1973 -37.60363,-14.17352 -46.93759,-23.51045 z"
                  transform="scale(0.26458333)"
                />
                <path
                  d="M 164.34408,15.340051 C 164.34408,15.340051 154.28478,14 128,14 117.79159,14.232469 54.706934,14.156544 31.214844,32.373047 17.999043,42.620993 13.933416,48.46538 18.429688,92.388672 c 0.168161,1.642732 0.350628,3.26575 0.56199,4.847115 C 79.909515,70.394713 127.81607,72.329215 127.81607,72.329215 c 19.41735,0.323339 32.75158,2.661497 40.15332,4.971761 0.0258,-19.887847 -0.0445,-45.129359 -3.62531,-61.960925 z"
                  transform="scale(0.26458333)"
                />
              </g>
            </svg>
          </div>
          <div>
            <h1>Login to Avoguard</h1>
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input type="text" name="email" placeholder="Email" id="email" autoComplete="on" />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input type="password" name="password" placeholder="Password" id="password" />
          </div>
          <div>
            <input type="submit" value="Login" />
          </div>
          <div>
            <p className="text-center">
              <small>
                Don't have an account?{' '}
                <a href="https://www.avoguard.com/sign-up" style={{ fontWeight: 'bold' }}>
                  Sign up
                </a>
              </small>
            </p>
          </div>
        </form>
      </div>
      <div className={style.copy}>
        <p>
          Design by{' '}
          <a href="https://www.linkedin.com/in/prajzler" target="_blank" rel="noreferrer">
            Vit Prajzler
          </a>
          <br />
          Copyright &copy; 2023 Avoguard. All right reserved.
        </p>
      </div>
    </>
  )
}
