import { Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FormEvent } from 'react'
import { toast } from 'react-toastify'
import MqttClientForm from './MqttClientForm'
import { apiPost } from '../fetch'

export default function MqttClientAdd() {
  const navigate = useNavigate()

  function onNewClientSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    apiPost('/clients', {
      id: data['id'],
      protocol: parseInt(data['protocol'] as string, 10),
      version: parseInt(data['version'] as string, 10),
      host: data['host'],
      port: parseInt(data['port'] as string, 10),
      timeout_s: parseInt(data['timeout_s'] as string, 10),
      retry_interval_s: parseInt(data['retry_interval_s'] as string, 10),
      retry_count: parseInt(data['retry_count'] as string, 10),
    })
      .then(() => {
        toast(`MQTT client '${data['id']}' created`, { type: 'success' })
        navigate('/mqtt')
      })
      .catch((err: Error) => {
        toast(`Error creating client, ${err.message}`, { type: 'error' })
      })
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/mqtt' }}>
            MQTT Clients
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/mqtt-new` }} active>
            New MQTT Client
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <Link to="/mqtt">MQTT Clients</Link> /{' '}
            </span>{' '}
            Add new client
          </h1>
          <p className="text-muted">
            MQTT Client is a persistent connection to an MQTT broker, used to send and receive packets from the broker.
            <br />
            Here you can configure parameters of the connection.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <form onSubmit={onNewClientSubmit}>
            <MqttClientForm />
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary">
                  Add new MQTT client
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-4">
          <dt>Protocol</dt>
          <dd>Defines which protocol and authentication mechanism will be used to connect to the broker.</dd>
          <dt>MQTT protocol version</dt>
          <dd>
            Choose a protocol that your MQTT broker supports. Use <code>3.1.1</code> for compatibility, <code>5.0</code>{' '}
            for advanced features.
          </dd>
          <dt>Host, Port</dt>
          <dd>
            Network parameters for the MQTT broker that this client should connect to. Default secure port is{' '}
            <code>8883</code>, default unsecure port is <code>1883</code>.
          </dd>
          <dt>Client ID</dt>
          <dd>
            Unique identifier for the client. Use only lowercase characters, numbers, underscores, or hyphens:{' '}
            <code>a-z 0-9 _ -</code>
          </dd>

          <dt>Advanced: Keep alive interval</dt>
          <dd>
            If no messages are exchanged between the client and the broker for <code>timeout</code> seconds, a ping
            message will be sent to the broker to keep the connection alive. Use <code>0</code> to disable keep alive.
          </dd>

          <dt>Advanced: Retry attempts and backoff</dt>
          <dd>
            <p>
              If the MQTT broker is not responding, the client will retry the delivery of the payload. It will try to
              deliver the payload <code>Retry attempts</code> times, waiting <code>Retry backoff</code> seconds to the
              power of the attempt number between each attempt (exponential backoff).
            </p>
            <span className="text-warning">Note: QoS 0 disables retries. The client will only make one attempt.</span>
          </dd>

          <dt>Where are the subscription filtering rules?</dt>
          <dd>You can add filtering rules for this client once it has been created.</dd>
        </div>
      </div>
    </Container>
  )
}
