import './Sidebar.css'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger, Dropdown } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import React from 'react'
import { API_URL } from './constants'
import { toast } from 'react-toastify'
import { useSession } from './SessionContext'

function NavigationItem(props: { title: string; link: string; icon: string; active?: boolean }) {
  const location = useLocation()
  const isActive = props.link === '/' ? location.pathname === '/' : location.pathname.startsWith(props.link)

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
      <li className="nav-item">
        <Link
          to={props.link}
          className={`nav-link py-3 border-bottom ${isActive && 'active'}`}
          aria-current="page"
          title={props.title}
        >
          <svg className="bi" width="24" height="24" role="img" aria-label={props.title}>
            <use xlinkHref={'#' + props.icon} />
          </svg>
        </Link>
      </li>
    </OverlayTrigger>
  )
}

export default function Sidebar() {
  const navigate = useNavigate()
  const { session, setSession } = useSession()

  function onLogout() {
    fetch(`${API_URL}/auth/logout`, { method: 'POST' }).then((result) => {
      if (result.ok) {
        toast('Logged out', { type: 'success' })
      } else {
        toast('Logout failed', { type: 'error' })
      }
      localStorage.removeItem('session')
      setSession(null)
      navigate('/login')
    })
  }

  const DropdownAvatar = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          props.onClick(e)
        }}
        className="d-flex align-items-center justify-content-center p-3 text-decoration-none dropdown-toggle"
      >
        <span className="rounded-circle">
          {session?.user.first_name[0]}
          {session?.user.last_name[0]}
        </span>
      </a>
    )
  })

  return (
    <nav id="sidebar" className="d-flex flex-column flex-shrink-0 bg-dark" style={{ width: '4.5rem' }}>
      <a href="/" className="d-block p-2 text-decoration-none border-bottom" title="Avoguard Gateway">
        <img id="logo" src="/logo57.png" alt="Avoguard Logo" width="57" height="57" title="Avoguard Gateway" />
        <div className="text-center mt-1">BETA</div>
      </a>
      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
        <NavigationItem link="/" title="Home" icon="home" />
        <NavigationItem link="/mqtt" title="MQTT Clients" icon="mqtt-clients" />
        <NavigationItem link="/http" title="HTTP Backends" icon="http-backends" />
        <NavigationItem link="/sub" title="Subscriptions" icon="subscriptions" />
        {/* <NavigationItem link="/data" title="Processing" icon="cpu-fill" />
        <NavigationItem link="/auth" title="Authentication" icon="lock" /> */}
        {/* <NavigationItem link="/config" title="Configuration" icon="gear-fill" /> */}
      </ul>
      <div className="dropdown border-top">
        <Dropdown>
          <Dropdown.Toggle as={DropdownAvatar} />
          <Dropdown.Menu>
            <Dropdown.Header>{session?.user.username}</Dropdown.Header>
            <Dropdown.Item as={Link} to="/profile">
              Profile
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onLogout}>Sign out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  )
}
