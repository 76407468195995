// This serves as a temporary login page for the app
// In production deployment, the login should happen through a CDN / worker
// that is separate from the main app
// This is to prevent the main app from being loaded if the user is not authenticated

import { toast } from 'react-toastify'
import { API_URL } from '../constants'
import { Link, useNavigate } from 'react-router-dom'
import { Breadcrumb, Container } from 'react-bootstrap'
import { useEffect } from 'react'
import { Session } from '../types'
import { useSession } from '../SessionContext'

export default function LoginDebug() {
  const { session, setSession } = useSession()
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (session === null) {
      const storedSession = localStorage.getItem('session')
      if (storedSession !== null) {
        setSession(JSON.parse(storedSession))
        navigate('/')
      }
    }
  })

  async function onSubmit(subpath: string, e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const data = Object.fromEntries(formData.entries())

    const response = await fetch(`${API_URL}/auth/${subpath}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      if (response.body !== null) {
        const value = await response.body
          .getReader()
          .read()
          .then((result) => new TextDecoder('ascii').decode(result.value))
        toast(`Error ${subpath}, ${value}`, { type: 'error' })
      } else {
        toast(`Error ${subpath}, ${response.statusText}`, { type: 'error' })
      }
      return
    }

    const session: Session = await response.json()
    localStorage.setItem('session', JSON.stringify(session))
    setSession(session)

    toast(`Success ${subpath}`, { type: 'success' })
  }

  function onLoginSubmit(e: React.FormEvent<HTMLFormElement>) {
    onSubmit('login', e).then(() => navigate('/'))
  }

  function onSignupSubmit(e: React.FormEvent<HTMLFormElement>) {
    onSubmit('signup', e).then(() => {
      toast('Please verify your email', { type: 'info' })
    })
  }

  function onVerifySubmit(e: React.FormEvent<HTMLFormElement>) {
    onSubmit('verify_email', e)
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: 'login' }} active>
            Mini Login
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>
      <div className="row">
        <div className="col-6 col-lg-4">
          <form onSubmit={onLoginSubmit}>
            <h2>Login</h2>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input type="text" className="form-control" id="email" name="email" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input type="password" className="form-control" id="password" name="password" />
            </div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </form>
        </div>
        <div className="col-6 col-lg-4">
          <form onSubmit={onSignupSubmit}>
            <h2>Sign up</h2>

            <div className="mb-3">
              <label htmlFor="signup-email" className="form-label">
                Email
              </label>
              <input type="text" className="form-control" id="signup-email" name="email" />
            </div>
            <div className="mb-3">
              <label htmlFor="first_name" className="form-label">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                name="first_name"
                title="First name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="form-label">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                placeholder="Last name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="nickname" className="form-label">
                Nickname
              </label>
              <input
                type="text"
                className="form-control"
                id="nickname"
                name="nickname"
                placeholder="Nickname"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input type="password" className="form-control" id="password" name="password" />
            </div>
            <button type="submit" className="btn btn-primary">
              Sign up
            </button>
          </form>
        </div>
        <div className="col-6 col-lg-4">
          <form onSubmit={onVerifySubmit}>
            <h2>Verify</h2>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                defaultValue={params.get('email') || undefined}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="verify" className="form-label">
                Verify
              </label>
              <input
                type="password"
                className="form-control"
                id="verify"
                name="verify"
                defaultValue={params.get('verify') || undefined}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Verify email
            </button>
          </form>
        </div>
      </div>
    </Container>
  )
}
