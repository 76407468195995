import { useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Subscription } from '../types'
import { apiGet } from '../fetch'
import { toast } from 'react-toastify'

export default function Subscriptions() {
  const [data, setData] = useState<Subscription[] | null>(null)

  useEffect(() => {
    document.title = 'Subscriptions - Avoguard'
    apiGet('/subscriptions')
      .then((data) => setData(data))
      .catch((err: Error) => toast('Could not retrieve subscriptions, ' + err.message, { type: 'error' }))
  }, [])

  function renderTableData(data: Subscription[] | null) {
    if (data === null) {
      return (
        <tr>
          <td colSpan={5}>Loading subscription list ...</td>
        </tr>
      )
    }
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={5}>No subscriptions found</td>
        </tr>
      )
    }

    return data.map((sub, index) => (
      <tr key={sub.id}>
        <td></td>
        <td>
          <Link to={`/sub/${encodeURIComponent(sub.id)}`}>
            <code>{sub.filter}</code>
          </Link>
        </td>
        <td>
          <Link to={`/mqtt/${sub.client_id}`}>
            <svg width="16" height="16" role="img" className="bi">
              <use xlinkHref={'#mqtt-clients'} />
            </svg>
          </Link>{' '}
          {sub.client_id}
        </td>
        <td>{sub.qos}</td>
        <td>
          <Link to={`/http/${sub.backend_id}`}>
            <svg width="16" height="16" role="img" className="bi">
              <use xlinkHref={'#http-backends'} />
            </svg>
          </Link>{' '}
          {sub.backend_id}
        </td>
      </tr>
    ))
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/sub' }} active>
            Subscriptions
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="toolbar">
          <Link to="/sub-new" className="btn btn-primary btn-avgrd">
            <svg width="18" height="18">
              <use xlinkHref="#subscriptions" />
            </svg>
            &nbsp; Add Subscription
          </Link>
        </div>
      </header>

      <div className="row">
        <div className="col">
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h1>
                {' '}
                <svg className="bi mt-2" width="32" height="32" role="img">
                  <use xlinkHref={'#subscriptions'} />
                </svg>{' '}
                Subscriptions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="text-muted">
            Subscriptions direct the flow of traffic from MQTT clients to HTTP backends.
            <br />
            All messages matching a subscription's topic filter will be forwarded according to the rules defined here.
          </p>
        </div>
      </div>

      <div className="panel">
        <div className="table-toolbar">
          {/* <div className="search row g-3 ms-auto">
            <div className="col-auto">
              <label className="col-form-label">Search</label>
            </div>
            <div className="col-auto">
              <input type="text" className="form-control" placeholder="search subscriptions"></input>
            </div>
          </div> */}
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Topic Filter</th>
              <th>Source MQTT Client ID</th>
              <th>Quality of Service</th>
              <th>Destination HTTP Backend ID</th>
            </tr>
          </thead>
          <tbody>{renderTableData(data)}</tbody>
        </table>
        <div className="table-bottombar"></div>
      </div>
    </Container>
  )
}
