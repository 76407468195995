import { Modal } from 'react-bootstrap'
import HttpBackendForm from './HttpBackendForm'
import { Backend } from '../types'
import { FormEvent } from 'react'
import { toast } from 'react-toastify'
import { apiPut } from '../fetch'

export default function HttpBackendEditModal(props: { backend: Backend; show: boolean; onHide: () => void }) {
  function onBackendEditSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    apiPut(`/backend/${props.backend.id}`, {
      host: data.host as string,
      port: parseInt(data.port as string, 10),
      protocol: parseInt(data.protocol as string, 10),
      method: parseInt(data.method as string, 10),
      content_type: data.content_type as string,
      path_prefix: data.path_prefix as string,
      timeout_s: parseInt(data.timeout_s as string, 10),
      retry_interval_s: parseInt(data.retry_interval_s as string, 10),
      retry_count: parseInt(data.retry_count as string, 10),
    })
      .then(() => {
        toast(`HTTP backend '${props.backend.id}' saved`, { type: 'success' })
        props.onHide()
      })
      .catch((err: Error) => {
        toast(`Error saving HTTP backend, ${err.message}`, { type: 'error' })
      })
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <form onSubmit={onBackendEditSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit HTTP Backend &quot;{props.backend.id}&quot;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HttpBackendForm backend={props.backend} />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex-grow-1">
            <button type="button" className="btn btn-secondary" onClick={() => props.onHide()}>
              Cancel
            </button>
            {/* &nbsp;
            <button type="submit" className="btn btn-danger">
              Save without testing
            </button> */}
          </div>
          <div className="d-flex">
            {/* <button type="submit" className="btn btn-warning" disabled>
              Test request
            </button>
            &nbsp;
            <div title="Please test your server configuration before saving changes.">
              <button type="submit" className="btn btn-primary" disabled>
                Save
              </button>
            </div> */}
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
