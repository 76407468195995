import { Routes, Route, Navigate } from 'react-router-dom'
import Icons from './Icons'
import Sidebar from './Sidebar'
import MqttClients from './mqtt/MqttClients'
import HttpBackends from './http/HttpBackends'
import HttpBackend from './http/HttpBackend'
import Home from './Home'
import HttpBackendAdd from './http/HttpBackendAdd'
import MqttClient from './mqtt/MqttClient'
// import Configuration from './config/Config'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import MqttEventObserver from './mqtt/MqttEventObserver'
import MqttClientAdd from './mqtt/MqttClientAdd'
import Profile from './Profile'
import Login from './auth/Login'
import { useSession } from './SessionContext'
import Subscriptions from './subs/Subscriptions'
import Subscription from './subs/Subscription'
import SubscriptionAdd from './subs/SubscriptionAdd'
import Activate from './auth/Activate'
import LoginDebug from './auth/LoginDebug'

function App() {
  const { session } = useSession()

  return (
    <div className="d-flex flex-grow-1">
      <Icons />
      <ToastContainer position="top-center" autoClose={2000} theme="dark" />
      {session !== undefined && session === null ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login-debug" element={<LoginDebug />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      ) : (
        <>
          <Sidebar />
          <main className="d-flex flex-grow-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Navigate to="/" replace />} />
              <Route path="/mqtt" element={<MqttClients />} />
              <Route path="/mqtt-new" element={<MqttClientAdd />} />
              <Route path="/mqtt/:id" element={<MqttClient />} />
              <Route path="/mqtt/:id/events" element={<MqttEventObserver />} />
              <Route path="/http" element={<HttpBackends />} />
              <Route path="/http-new" element={<HttpBackendAdd />} />
              <Route path="/http/:id" element={<HttpBackend />} />
              <Route path="/sub" element={<Subscriptions />} />
              <Route path="/sub/:id" element={<Subscription />} />
              <Route path="/sub-new" element={<SubscriptionAdd />} />
              {/* <Route path="/config" element={<Configuration />} /> */}
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </main>
        </>
      )}
    </div>
  )
}

export default App
