import { intlFormat } from 'date-fns'
import { useEffect, useState } from 'react'
import { TrafficStats } from '../types'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { apiGet } from '../fetch'
import { toast } from 'react-toastify'

function parseTrafficJson(scale: 'hour' | 'day' | 'month', data: TrafficStats[]): TrafficStats[] {
  const lastTimestamp = new Date(data[data.length - 1].Timestamp)
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (scale === 'hour') {
      item.Timestamp = new Date(lastTimestamp.getTime() - (data.length - i - 1) * 60000)
      item.name = intlFormat(item.Timestamp, { hour: '2-digit', minute: '2-digit' })
    } else if (scale === 'day') {
      item.Timestamp = new Date(lastTimestamp.getTime() - (data.length - i - 1) * 60 * 60000)
      item.name = intlFormat(item.Timestamp, { hour: 'numeric' })
    } else if (scale === 'month') {
      item.Timestamp = new Date(lastTimestamp.getTime() - (data.length - i - 1) * 24 * 60 * 60000)
      item.name = intlFormat(item.Timestamp, { month: 'numeric', day: 'numeric' })
    }
  }
  return data
}

export default function TrafficChart(props: { component: 'client' | 'backend'; id: string }) {
  const [trafficHour, setTrafficHour] = useState<TrafficStats[] | null>(null)
  const [trafficDay, setTrafficDay] = useState<TrafficStats[] | null>(null)
  const [trafficMonth, setTrafficMonth] = useState<TrafficStats[] | null>(null)
  const [trafficScale, setTrafficScale] = useState<'hour' | 'day' | 'month'>('hour')
  const [chartData, setChartData] = useState<TrafficStats[] | null>(null)
  const [chartUnits, setChartUnits] = useState<'bytes' | 'packets'>('bytes')

  const traffic = {
    hour: trafficHour,
    day: trafficDay,
    month: trafficMonth,
  }
  const setTraffic = {
    hour: setTrafficHour,
    day: setTrafficDay,
    month: setTrafficMonth,
  }

  useEffect(() => {
    if (traffic[trafficScale] === null) {
      apiGet(`/${props.component}/${props.id}/traffic/${trafficScale}`)
        .then((data) => {
          setTraffic[trafficScale](parseTrafficJson(trafficScale, data))
          setChartData(data)
        })
        .catch((err) => {
          toast(`Error fetching traffic data, ${(err as Error).message}`, { type: 'error' })
        })
    } else {
      setChartData(traffic[trafficScale])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficScale, props.id, props.component])

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="header">
            <h2>
              Traffic history -{' '}
              {props.component === 'client' ? chartUnits : chartUnits === 'bytes' ? 'bytes' : 'requests / responses'} in
              last {trafficScale === 'hour' ? '60 minutes' : trafficScale === 'day' ? '24 hours' : '30 days'}
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3 d-flex flex-row">
        <div className="flex-shrink-1" style={{ width: '120px' }}>
          <div className="btn-group-vertical" style={{ width: '120px' }}>
            <button
              className={`btn btn-secondary ${trafficScale === 'hour' ? 'active' : ''}`}
              onClick={() => setTrafficScale('hour')}
            >
              60 minutes
            </button>
            <button
              className={`btn btn-secondary ${trafficScale === 'day' ? 'active' : ''}`}
              onClick={() => setTrafficScale('day')}
            >
              24 hours
            </button>
            <button
              className={`btn btn-secondary ${trafficScale === 'month' ? 'active' : ''}`}
              onClick={() => setTrafficScale('month')}
            >
              30 days
            </button>
          </div>
          <div className="btn-group-vertical mt-3" style={{ width: '120px' }}>
            <button
              className={`btn btn-secondary ${chartUnits === 'bytes' ? 'active' : ''}`}
              onClick={() => setChartUnits('bytes')}
            >
              in bytes
            </button>
            <button
              className={`btn btn-secondary ${chartUnits === 'packets' ? 'active' : ''}`}
              onClick={() => setChartUnits('packets')}
            >
              {props.component === 'client' ? 'in packets' : 'in req / res'}
            </button>
          </div>
        </div>
        <div className="col">
          <ResponsiveContainer height={250} width="99%">
            <BarChart
              data={chartData || []}
              margin={{
                top: 4,
                right: 0,
                left: 20,
                bottom: 4,
              }}
            >
              <CartesianGrid strokeDasharray="2 2" stroke="#333" />
              <XAxis dataKey="name" tickLine={{ stroke: '#222', style: { stroke: '#222' } }} />
              <YAxis
                unit={chartUnits === 'bytes' ? ' B' : props.component === 'client' ? ' p' : ' r'}
                tickLine={{ stroke: '#222', style: { stroke: '#222' } }}
              />
              <Tooltip
                itemStyle={{}}
                cursor={{ fill: '#333' }}
                contentStyle={{
                  backgroundColor: '#333',
                  padding: '4px 8px',
                  border: '0px',
                  boxShadow: '0px 0px 8px rgba(0,0,0,0.5)',
                }}
                labelStyle={{ backgroundColor: '#333' }}
              />
              <Legend verticalAlign="middle" align="right" width={40} />
              <Bar
                name="RX"
                stackId="a"
                dataKey={chartUnits === 'bytes' ? 'RxBytes' : 'RxPackets'}
                fill="var(--avgrd-blue)"
                label="Uplink"
              />
              <Bar
                name="TX"
                stackId="a"
                dataKey={chartUnits === 'bytes' ? 'TxBytes' : 'TxPackets'}
                fill="var(--avgrd-orange)"
              />
              <title>Uplink and downlink in last 60 minutes</title>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  )
}
