import { useEffect, useState } from 'react'
import { Backend } from '../types'
import { Collapse } from 'react-bootstrap'

const defaults = {
  Protocol: '0',
  Method: '0',
  Port: 80,
  Retry: {
    Max: 2,
    IntervalSeconds: 10,
  },
  Timeout: 3,
}

export default function HttpBackendForm(props: { backend?: Backend }) {
  const [backendId, setBackendId] = useState<string>('')
  const [openAdvanced, setOpenAdvanced] = useState<boolean>(false)
  const [port, setPort] = useState<number>(props.backend?.port || defaults.Port)
  const [urlTemplate, setUrlTemplate] = useState<string>()
  const backend = props.backend

  useEffect(() => {
    updateUrlTemplate()
  }, [props.backend])

  function onHostChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBackendId(e.target.value)
    updateUrlTemplate()
  }

  function onProtocolChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setPort(e.target.value === '0' ? 80 : 443)
    setTimeout(updateUrlTemplate, 0)
  }

  function onPortChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPort(parseInt(e.target.value, 10))
    setTimeout(updateUrlTemplate, 0)
  }

  function onBackendIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBackendId(e.target.value)
  }

  function updateUrlTemplate() {
    const protocol = document.getElementById('backend-protocol') as HTMLSelectElement
    const host = document.getElementById('backend-host') as HTMLInputElement
    const port = document.getElementById('backend-port') as HTMLInputElement
    const pathPrefix = document.getElementById('backend-path') as HTMLInputElement
    const method = document.getElementById('backend-method') as HTMLSelectElement

    let url = `${method.options[method.selectedIndex].text} ${protocol.options[
      protocol.selectedIndex
    ].text.toLowerCase()}://${host.value}:${port.value}/${pathPrefix.value}[MQTT topic]`
    setUrlTemplate(url)
  }

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-3">
          <label htmlFor="backend-protocol" className="form-label">
            Protocol
          </label>
          <select
            className="form-select"
            id="backend-protocol"
            name="protocol"
            defaultValue={backend?.protocol || defaults.Protocol}
            onChange={onProtocolChange}
          >
            <option value="0">HTTP</option>
            <option value="1">HTTPS</option>
            {/* <option>HTTP/2</option>
            <option>HTTPS/2</option>
            <option>HTTP/3</option>
            <option>HTTPS/3</option> */}
          </select>
        </div>
        <div className="col-6">
          <label htmlFor="backend-host" className="form-label">
            Host
          </label>
          <input
            type="text"
            className="form-control"
            id="backend-host"
            defaultValue={backend?.host}
            name="host"
            onChange={backend === undefined ? onHostChange : undefined}
            required
          />
        </div>
        <div className="col-3">
          <label htmlFor="backend-port" className="form-label">
            Port
          </label>
          <input
            type="text"
            className="form-control"
            id="backend-port"
            value={port}
            name="port"
            onChange={onPortChange}
            required
          />
        </div>
      </div>
      {backend === undefined && (
        <div className="mb-3">
          <label htmlFor="backend-id" className="form-label">
            Backend ID
          </label>
          <input
            type="text"
            className="form-control"
            id="backend-id"
            name="id"
            value={backendId}
            onChange={onBackendIdChange}
            required
          />
          <p>
            <small className="text-muted">
              Make sure to choose a unique ID. It cannot be changed once the HTTP backend is added.
            </small>
          </p>
        </div>
      )}
      <div className="row">
        <div className="mb-3 col-3">
          <label htmlFor="backend-method" className="form-label">
            Method
          </label>
          <select
            className="form-select"
            id="backend-method"
            defaultValue={backend?.method || defaults.Method}
            name="method"
            onChange={updateUrlTemplate}
          >
            <option value="0">POST</option>
            <option value="1">PUT</option>
            <option disabled>GET</option>
          </select>
        </div>
        <div className="mb-3 col-9">
          <label htmlFor="backend-path" className="form-label">
            Path prefix
          </label>
          <input
            type="text"
            className="form-control"
            id="backend-path"
            name="path_prefix"
            defaultValue={backend?.path_prefix}
            onChange={updateUrlTemplate}
          />
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-12">
          <label htmlFor="backend-content-type" className="form-label">
            Content-Type header value
          </label>
          <input
            type="text"
            className="form-control"
            id="backend-content-type"
            name="content_type"
            defaultValue={backend?.content_type}
          />
        </div>
      </div>
      <div>
        <p>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => {
              setOpenAdvanced(!openAdvanced)
            }}
          >
            Advanced settings
          </button>
        </p>
        <Collapse in={openAdvanced}>
          <div>
            <div className="row g-3 mb-3">
              <div className="col-4">
                <label htmlFor="backend-retry-count" className="form-label">
                  Retry attempts
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="backend-retry-count"
                  defaultValue={backend ? backend.retry_count : defaults.Retry.Max}
                  name="retry_count"
                  required
                />
              </div>
              <div className="col-8">
                <label htmlFor="backend-retry-interval-s" className="form-label">
                  Retry backoff <span className="small">[seconds]</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="backend-retry-interval-s"
                  defaultValue={backend ? backend.retry_interval_s : defaults.Retry.IntervalSeconds}
                  name="retry_interval_s"
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="backend-timeout" className="form-label">
                Timeout <span className="small">[seconds]</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="backend-timeout"
                defaultValue={backend ? backend.timeout_s : defaults.Timeout}
                name="timeout_s"
                required
              />
            </div>
          </div>
        </Collapse>
      </div>
      <div className="mb-3">
        <p>
          Request template:
          <br /> <code>{urlTemplate}</code>
        </p>
      </div>
      {/* <div className="mb-3">
        <label htmlFor="backend-headers" className="form-label">
          Headers
        </label>
        <input type="text" className="form-control" id="backend-headers" />
      </div> */}
    </>
  )
}
