import { useEffect } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Home() {
  useEffect(() => {
    document.title = 'Home - Avoguard Gateway'
  }, [])

  return (
    <Container>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>
      <div className="row">
        <div className="col">
          <h1>Avoguard Gateway</h1>
          <p className="text-muted">
            Avoguard Gateway is a MQTT to HTTP gateway that allows you to extract data from MQTT packets and forward it
            to HTTP servers.
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6 col-lg-4">
          <div className="mt-4 d-flex flex-row">
            <div>
              <svg className="bi mt-2" width="80" height="80" role="img" style={{ fill: 'var(--avgrd-blue)' }}>
                <use xlinkHref={'#mqtt-clients'} />
              </svg>{' '}
            </div>
            <div className="ms-3">
              <h2>
                <Link to="/mqtt">MQTT Clients</Link>
              </h2>
              <p>
                MQTT Client connects to an MQTT broker and subscribes to all the topics that are configured in its
                subscription filters.
              </p>
            </div>
          </div>
          <div className="mt-4 d-flex flex-row">
            <div>
              <svg className="bi mt-2" width="80" height="80" role="img" style={{ fill: 'var(--avgrd-blue)' }}>
                <use xlinkHref={'#http-backends'} />
              </svg>{' '}
            </div>
            <div className="ms-3">
              <h2>
                <Link to="/http">HTTP Backends</Link>
              </h2>
              <p>
                HTTP Backends define the target HTTP servers that will receive payloads extracted from MQTT packets.
              </p>
            </div>
          </div>
          <div className="mt-4 d-flex flex-row">
            <div>
              <svg className="bi mt-2" width="80" height="80" role="img" style={{ fill: 'var(--avgrd-blue)' }}>
                <use xlinkHref={'#subscriptions'} />
              </svg>{' '}
            </div>
            <div className="ms-3">
              <h2>
                <Link to="/sub">Subscriptions</Link>
              </h2>
              <p>
                Subscriptions link together MQTT clients and HTTP backends. They define which data should be routed to
                which destination.
              </p>
            </div>
          </div>
          {/* <div className="mt-4 d-flex flex-row">
            <div>
              <svg className="bi mt-2" width="70" height="70" role="img" style={{ fill: 'var(--avgrd-blue)' }}>
                <use xlinkHref={'#gear-fill'} />
              </svg>{' '}
            </div>
            <div className="ms-3">
              <h2>
                <Link to="/config">Global Configuration</Link>
              </h2>
              <p className="text-muted">
                Configure the global settings of the gateway, such as the authentication method and the logging level.
              </p>
            </div>
          </div> */}
        </div>

        <div className="col-md-6 col-lg-6 mt-4">
          <div>
            <h2>Getting started</h2>
            <p className="text-muted">
              To get started, you need to configure the MQTT clients that will connect to the MQTT broker and the target
              HTTP backends that will receive the extracted data.
            </p>
            <p className="text-muted">
              Once you have configured the MQTT clients and the HTTP backends, the gateway will automatically start
              forwarding the data.
            </p>
            <p className="text-muted">
              You can observe the data that is being forwarded by using the MQTT Client <em>tap</em> feature, which you
              can find in the MQTT Client details page.
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}
