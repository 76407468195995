import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './HttpBackend.module.css'
import { Backend, HttpMethodToString, HttpProtocolToString } from '../types'
import TrafficChart from '../components/TrafficChart'
import HttpBackendEditModal from './HttpBackendEditModal'
import HttpBackendDeleteModal from './HttpBackendDeleteModal'
import SubscriptionsModal from '../components/SubscriptionsModal'
import { apiGet } from '../fetch'
import { toast } from 'react-toastify'
import SubscriptionsTable from '../components/SubscriptionsTable'

export default function HttpBackend() {
  const { id } = useParams()
  const [backend, setData] = useState<Backend | null>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = id + ' - HTTP Backends - Avoguard'
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  function refresh() {
    apiGet('/backend/' + id)
      .then((data) => setData(data))
      .catch((err) => toast('Could not get backend data, ' + (err as Error).message, { type: 'error' }))
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/http' }}>
            HTTP Backends
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/http/${id}` }} active>
            {id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="align-self-center">
          <button type="button" className="btn btn-info" onClick={refresh}>
            Refresh
          </button>
          <button className="btn btn-danger" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
          <button className="btn btn-primary" onClick={() => setShowSubscriptionsModal(true)}>
            Edit subscriptions
          </button>
          <button className="btn btn-primary" onClick={() => setShowEditModal(true)}>
            <svg className="bi" width="18" height="18">
              <use xlinkHref="#http-backends" />
            </svg>{' '}
            Edit backend
          </button>
        </div>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <svg className="bi mt-2" width="32" height="32" role="img">
                <use xlinkHref={'#http-backends'} />
              </svg>{' '}
              <Link to="/http">HTTP Backends</Link> /{' '}
            </span>{' '}
            {id}
          </h1>
          <p className="text-muted">
            A HTTP backend is the target HTTP server that will receive payloads extracted from MQTT packets. <br />
            Here you can define the delivery configuration and the packet filtering rules.
          </p>
        </div>
      </div>

      <TrafficChart component="backend" id={id!} />

      <div className="row mb-3">
        <div className="col col-12 col-lg-4">
          <div className="header">
            <div className="flex-grow-1">
              <h2>Status</h2>
            </div>
          </div>
          <div className="panel">
            {backend !== null && (
              <table className="details">
                <tbody>
                  <tr>
                    <td>Last response code</td>
                    <td>
                      {backend.last_response_code === 0 ? (
                        <span className="text-muted">N/A</span>
                      ) : Math.floor(backend.last_response_code / 100) === 2 ? (
                        <Badge bg="success">{backend.last_response_code}</Badge>
                      ) : (
                        <Badge bg="danger">{backend.last_response_code}</Badge>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="header">
            <div className="flex-grow-1">
              <h2>Configuration</h2>
            </div>
          </div>
          <div className="panel">
            {backend !== null ? (
              <table className="details">
                <tbody>
                  <tr>
                    <td>Backend&nbsp;ID</td>
                    <td>{backend.id}</td>
                  </tr>
                  <tr>
                    <td>Host</td>
                    <td>
                      <code>
                        {backend.host}:{backend.port}
                      </code>
                    </td>
                  </tr>
                  <tr>
                    <td>Protocol</td>
                    <td>{HttpProtocolToString[backend.protocol]}</td>
                  </tr>
                  <tr>
                    <td>Method</td>
                    <td>{HttpMethodToString[backend.method]}</td>
                  </tr>
                  <tr>
                    <td>Path prefix</td>
                    <td>
                      {backend.path_prefix === '' ? (
                        <span className="text-muted">(no prefix)</span>
                      ) : (
                        backend.path_prefix
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Content type</td>
                    <td>{backend.content_type}</td>
                  </tr>
                  <tr>
                    <td>Retry</td>
                    <td>
                      {backend.retry_count} attempts, {backend.retry_interval_s}s backoff
                    </td>
                  </tr>
                  <tr>
                    <td>Timeout</td>
                    <td>
                      {backend.timeout_s === 0 ? (
                        <span className="text-danger">wait forever (dangerous!)</span>
                      ) : (
                        `${backend.timeout_s}s`
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2} className="pt-3 ps-3">
                      Request template
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-center">
                      <code>{`${HttpMethodToString[backend.method]} ${HttpProtocolToString[
                        backend.protocol
                      ].toLowerCase()}://${backend.host}:${backend.port}/${backend.path_prefix}[MQTT Topic]`}</code>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
        <div className="col col-12 col-lg-8">
          <div className="header">
            <h2>Subscriptions</h2>
          </div>
          <div className="panel">
            {backend !== null && <SubscriptionsTable subscriptions={backend.subscriptions} mode="backend" />}
          </div>
        </div>
      </div>
      {backend && (
        <HttpBackendEditModal
          backend={backend!}
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false)
            refresh()
          }}
        />
      )}
      {backend && (
        <SubscriptionsModal
          subscriptions={backend.subscriptions}
          backendId={backend.id}
          show={showSubscriptionsModal}
          onHide={() => {
            setShowSubscriptionsModal(false)
          }}
          onSubmit={() => {
            setShowSubscriptionsModal(false)
            refresh()
          }}
        />
      )}
      {backend && (
        <HttpBackendDeleteModal
          backendId={backend.id}
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false)
          }}
          onDeleted={() => {
            navigate('/http')
          }}
        />
      )}
    </Container>
  )
}
