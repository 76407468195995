import { Modal } from 'react-bootstrap'
import { Client } from '../types'
import { FormEvent } from 'react'
import MqttClientForm from './MqttClientForm'
import { toast } from 'react-toastify'
import { apiPut } from '../fetch'

export default function MqttClientEditModal(props: {
  client: Client | null
  show: boolean
  onHide: () => void
  onSubmit: () => void
}) {
  const { client } = props

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    apiPut('/client/' + client!.id, {
      host: data['host'] as string,
      port: parseInt(data['port'] as string, 10),
      protocol: parseInt(data['protocol'] as string, 10),
      version: parseInt(data['version'] as string, 10),
      keepalive_s: parseInt(data['keepalive_s'] as string, 10),
      retry_count: parseInt(data['retry_count'] as string, 10),
      retry_interval_s: parseInt(data['retry_interval_s'] as string, 10),
    })
      .then(() => {
        toast(`MQTT client updated.`, { type: 'success' })
        props.onSubmit()
      })
      .catch((err: Error) => toast(`Error updating MQTT client, ${err.message}`, { type: 'error' }))
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit MQTT Client &quot;{client?.id}&quot;</Modal.Title>
        </Modal.Header>
        <Modal.Body>{client !== null && <MqttClientForm client={client} />}</Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={props.onHide}>
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Save changes
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
