export function generateBackgroundItems() {
  const itemSize = 128
  const itemCount = 1200
  const templates = [
    { backgroundColor: '#303030' },
    { backgroundColor: 'hsl(158, 99%, 42%)' },
    { backgroundColor: 'hsl(33, 99%, 42%)' },
    { backgroundColor: 'hsl(182, 94%, 42%)' },
  ]

  let items = []
  for (let i = 0; i < itemCount; i++) {
    let height = itemSize / 2 + Math.random() * itemSize
    let width = itemSize / 2 + Math.random() * itemSize
    let backgroundColor = templates[Math.floor(Math.random() * templates.length)].backgroundColor
    // let left = Math.random() * (bg.clientWidth + itemSize) - itemSize + 'px'
    // let top = Math.random() * (bg.clientHeight + itemSize) - itemSize + 'px'
    let left = Math.random() * (window.innerWidth + itemSize) - itemSize + 'px'
    let top = Math.random() * (window.innerHeight + itemSize) - itemSize + 'px'
    items.push({ height, width, backgroundColor, left, top })
  }

  return items
}
