import { API_URL } from './constants'

export async function apiGet(path: string) {
  const res = await fetch(API_URL + path)
  if (!res.ok) {
    if (res.status === 401) {
      // Unauthorized
      localStorage.removeItem('session')
      window.location.href = '/login'
      throw new Error('Unauthorized')
    }
    throw new Error(res.statusText)
  }
  return res.json()
}

export async function apiPost(path: string, body: any) {
  const res = await fetch(API_URL + path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return
}

export async function apiPut(path: string, body: any) {
  const res = await fetch(API_URL + path, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return
}

export async function apiDelete(path: string) {
  const res = await fetch(API_URL + path, { method: 'DELETE' })
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return
}
