import { useEffect, useState } from 'react'
import { Badge, Breadcrumb, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { HttpBackendListItem, HttpMethodToString, HttpProtocolToString } from '../types'
import { apiGet } from '../fetch'
import { toast } from 'react-toastify'

export default function HttpBackends() {
  const [data, setData] = useState<HttpBackendListItem[] | null>(null)

  useEffect(() => {
    document.title = 'HTTP Backends - Avoguard'
    apiGet('/backends')
      .then((data) => setData(data))
      .catch((err) => toast(`Error fetching backends, ${err.message}`, { type: 'error' }))
  }, [])

  function renderTableData(data: HttpBackendListItem[] | null) {
    if (data === null) {
      return (
        <tr>
          <td colSpan={5}>Loading HTTP backend list ...</td>
        </tr>
      )
    }
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={5}>No HTTP backends found</td>
        </tr>
      )
    }

    return data.map((backend) => (
      <tr key={backend.id}>
        <td></td>
        <td>
          <Link to={`/http/${backend.id}`}>{backend.id}</Link>
        </td>
        <td>{HttpMethodToString[backend.method]}</td>
        <td>{HttpProtocolToString[backend.protocol]}</td>
        <td>
          <code>{backend.host}</code>
        </td>
        <td>{backend.port}</td>
        <td>{backend.path_prefix ? <code>{backend.path_prefix}</code> : <span className="text-muted">-</span>}</td>
        <td>{backend.content_type}</td>
        <td>{backend.subscription_count}</td>
        <td>
          {backend.last_response_code === 0 ? (
            <span className="text-muted">N/A</span>
          ) : Math.floor(backend.last_response_code / 100) === 2 ? (
            <Badge bg="success">{backend.last_response_code}</Badge>
          ) : (
            <Badge bg="danger">{backend.last_response_code}</Badge>
          )}
        </td>
      </tr>
    ))
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/http' }} active>
            HTTP Backends
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="toolbar">
          <Link to="/http-new" className="btn btn-primary btn-avgrd">
            <svg className="bi" width="18" height="18">
              <use xlinkHref="#http-backends" />
            </svg>
            &nbsp; Add HTTP Backend
          </Link>
        </div>
      </header>

      <div className="row">
        <div className="col">
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h1>
                {' '}
                <svg className="bi mt-2" width="32" height="32" role="img">
                  <use xlinkHref={'#http-backends'} />
                </svg>{' '}
                HTTP Backends
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="text-muted">
            HTTP Backends define the target HTTP Servers that will receive extracted payloads from MQTT packets. <br />
            Each backend has a set of filtering rules that define which MQTT packets will be forwarded to it.
          </p>
        </div>
      </div>

      <div className="panel">
        <div className="table-toolbar">
          {/* <nav className="nav">
            <a href="#" className="nav-link active">
              All <span className="badge bg-secondary">12</span>
            </a>
            <a href="#" className="nav-link">
              Online <span className="badge bg-secondary">10</span>
            </a>
            <a href="#" className="nav-link">
              Offline <span className="badge bg-secondary">2</span>
            </a>
          </nav> */}
          {/* <div className="search row g-3 ms-auto">
            <div className="col-auto">
              <label className="col-form-label">Search</label>
            </div>
            <div className="col-auto">
              <input type="text" className="form-control" placeholder="search backends by ID"></input>
            </div>
          </div> */}
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Backend ID</th>
              <th>HTTP Method</th>
              <th>Protocol</th>
              <th>Target host</th>
              <th>Target port</th>
              <th>Path prefix</th>
              <th>Content-Type</th>
              <th>Subscriptions</th>
              <th>Last Response Code</th>
            </tr>
          </thead>
          <tbody>{renderTableData(data)}</tbody>
        </table>
        <div className="table-bottombar"></div>
      </div>
    </Container>
  )
}
