import { useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { apiDelete, apiGet } from '../fetch'
import { Subscription as SubscriptionType } from '../types'
import { toast } from 'react-toastify'

export default function Subscription() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [sub, setSub] = useState<SubscriptionType | null>(null)

  useEffect(() => {
    document.title = `Subscription ${id} - Avoguard`
    apiGet(`/subscription/${id}`)
      .then((data) => {
        setSub(data)
        document.title = `Subscription ${data.filter} - Avoguard`
      })
      .catch((err) => toast('Could not get subscription data, ' + (err as Error).message, { type: 'error' }))
  }, [id])

  function onDeleteSubscriptionClick() {
    apiDelete(`/subscription/${id}`)
      .then(() => {
        toast(`Subscription ${sub?.filter} deleted`, { type: 'success' })
        navigate('/sub')
      })
      .catch((err) => toast('Could not delete subscription, ' + (err as Error).message, { type: 'error' }))
  }

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/sub' }}>
            Subscriptions
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/sub/${id}` }} active>
            {id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="align-self-center">
          <button className="btn btn-danger" onClick={onDeleteSubscriptionClick}>
            Delete subscription
          </button>
        </div>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <svg className="bi mt-2" width="32" height="32" role="img">
                <use xlinkHref={'#subscriptions'} />
              </svg>{' '}
              <Link to="/sub">Subscriptions</Link> /{' '}
            </span>{' '}
            <small style={{ fontFamily: 'monospace' }}>{sub ? sub.filter : ''}</small>
          </h1>
          <p className="text-muted">
            A subscription is a rule that defines how data should flow from an MQTT source to a HTTP destination.
          </p>
        </div>
      </div>

      {sub && (
        <div className="row">
          <div className="col col-12 col-lg-4">
            <div className="header">
              <h2>Source</h2>
            </div>
            <div className="panel">
              <table className="details">
                <tbody>
                  <tr>
                    <td>Source type</td>
                    <td>MQTT Client</td>
                  </tr>
                  <tr>
                    <td>MQTT Client ID</td>
                    <td>
                      <Link to={`/mqtt/${sub.client_id}`}>{sub.client_id}</Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="header">
              <h2>Configuration</h2>
            </div>
            <div className="panel">
              <table className="details">
                <tbody>
                  <tr>
                    <td>Topic filter</td>
                    <td>
                      <code>{sub.filter}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Quality of Service</td>
                    <td>{sub.qos}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="header">
              <h2>Destination</h2>
            </div>
            <div className="panel">
              <table className="details">
                <tbody>
                  <tr>
                    <td>Destination type</td>
                    <td>HTTP Backend</td>
                  </tr>
                  <tr>
                    <td>HTTP Backend ID</td>
                    <td>
                      <Link to={`/http/${sub.backend_id}`}>{sub.backend_id}</Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}
