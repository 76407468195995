import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { API_URL } from '../constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Activate() {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string | null>(null)
  const [errMessage, setErrMessage] = useState<string | null>(null)
  const [activated, setActivated] = useState<boolean>(false)

  document.title = 'Account activation - Avoguard'

  useEffect(() => {
    async function activate() {
      const urlParams = new URLSearchParams(window.location.search)
      const email = urlParams.get('email')
      const verify = urlParams.get('verify')

      setEmail(email)

      if (email === null || verify === null) {
        setErrMessage('Invalid activation link.')
        return
      }

      const response = await fetch(`${API_URL}/auth/verify_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, verify }),
      })

      if (!response.ok) {
        if (response.body !== null) {
          const value = await response.body
            .getReader()
            .read()
            .then((result) => new TextDecoder('ascii').decode(result.value))
          setErrMessage(`Activation error, ${value || response.statusText}`)
        } else {
          setErrMessage(`Activation error, ${response.statusText} (no further information)`)
        }
        return
      }

      setActivated(true)
      setTimeout(() => {
        toast('Activation successful.', { type: 'success' })
        navigate('/login', { replace: false })
      }, 1000)
    }

    activate()
  }, [navigate])

  return (
    <div className="d-flex justify-content-center w-100 align-self-center flex-row">
      <div className="me-4 align-self-center" style={{ visibility: errMessage !== null ? 'hidden' : 'visible' }}>
        <Spinner animation="border" role="status" style={{ color: 'var(--avgrd-blue)', width: '4em', height: '4em' }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      {errMessage !== null ? (
        <div>
          <h2>Activation failed.</h2>
          <small className="text-danger">{errMessage}</small>
        </div>
      ) : activated ? (
        <div>
          <h2>Activation successful.</h2>
          <small className="text-muted">Redirecting to log in screen...</small>
        </div>
      ) : (
        <div>
          <h2>Activating your Avoguard account ...</h2>
          <small className="text-muted">
            {email !== null ? (
              <>
                Activating <strong>{email}</strong>.{' '}
              </>
            ) : (
              ''
            )}
            This may take a few seconds.
          </small>
        </div>
      )}
    </div>
  )
}
