import React, { createContext, useContext, useEffect, useState } from 'react'
import { Session } from './types'

interface SessionContextType {
  session: Session | null | undefined
  setSession: React.Dispatch<React.SetStateAction<Session | null | undefined>>
}

const SessionContext = createContext<SessionContextType | undefined>(undefined)

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // undefined = session not yet loaded
  // null === session loaded, but not set (not logged in)
  // Session = logged in
  const [session, setSession] = useState<Session | null | undefined>(undefined)

  useEffect(() => {
    if (session === undefined) {
      const storedSession = localStorage.getItem('session')
      if (storedSession !== null) {
        setSession(JSON.parse(storedSession))
      } else {
        setSession(null)
      }
    }
  }, [session])

  return <SessionContext.Provider value={{ session, setSession }}>{children}</SessionContext.Provider>
}

export const useSession = (): SessionContextType => {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context
}
