import { FormEvent } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { apiPost } from '../fetch'
import { toast } from 'react-toastify'
import SubscriptionForm from './SubscriptionForm'

export default function SubscriptionAdd() {
  const navigate = useNavigate()

  function onNewSubscriptionSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    apiPost('/subscriptions', [
      {
        client_id: data['client_id'],
        backend_id: data['backend_id'],
        filter: data['filter'],
        qos: parseInt(data['qos'] as string, 10),
      },
    ])
      .then(() => {
        toast(`Subscription created`, { type: 'success' })
        navigate('/sub')
      })
      .catch((err: Error) => toast(`Error creating subscription, ${err.message}`, { type: 'error' }))
  }

  return (
    <Container>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/sub' }}>
            Subscriptions
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/sub-new` }} active>
            Add new subscription
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <Link to="/sub">Subscriptions</Link> /{' '}
            </span>{' '}
            Add new subscription
          </h1>
          <p className="text-muted">
            Subscriptions are the rules for message routing. <br />
            They define which messages from which MQTT clients will be forwarded to forward to which HTTP backends.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-6 mb-4">
          <form onSubmit={onNewSubscriptionSubmit}>
            {<SubscriptionForm />}
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary">
                  Add new subscription
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-6">
          <dt>Source client ID</dt>
          <dd>The MQTT client that will be the source of the messages to be forwarded.</dd>

          <dt>Topic filter</dt>
          <dd>
            <p>The MQTT topic filter that will be used to match the messages to be forwarded.</p>
            <p>
              Use <code style={{ fontSize: '1.2em' }}>/</code> as level separator.
            </p>
            <p>You can use the following wildcards:</p>
            <ul>
              <li>
                <code style={{ fontSize: '1.2em' }}>+</code> matches a single level of the topic
              </li>
              <li>
                <code style={{ fontSize: '1.2em' }}>#</code> matches all remaining levels of the topic
              </li>
            </ul>
            <p>
              Refer to the{' '}
              <a href="https://mqtt.org/mqtt-specification/" target="_blank" rel="noreferrer">
                MQTT specification
              </a>{' '}
              for more details on topic filters.
            </p>
            <p>Examples:</p>
            <p>
              <code className="me-2">a/b/c</code> &nbsp;
              <code className="me-2">a/+/c</code> &nbsp;
              <code className="me-2">a/#</code> &nbsp;
              <code className="me-2">#</code> &nbsp;
              <code className="me-2">+/b</code>
              <code className="me-2">a/+/+</code>
              <code className="me-2">a/+/b/#</code>
            </p>
          </dd>

          <dt>Quality of service (QoS)</dt>
          <dd>
            <p>
              The MQTT Quality of Service level that the MQTT client will request from the MQTT broker when this
              subscription is created.
            </p>
            <p>The MQTT specification defines three levels of QoS:</p>
            <ul>
              <li>QoS 0 - At most once delivery</li>
              <li>QoS 1 - At least once delivery</li>
              <li>QoS 2 - Exactly once delivery</li>
            </ul>
            <p>
              Not all MQTT brokers support QoS 2 or QoS 1. Unless you have specific requirements, choose QoS 0 for best
              performance.
            </p>
          </dd>

          <dt>Destination backend ID</dt>
          <dd>The HTTP backend that will receive the messages that match the topic filter.</dd>
        </div>
      </div>
    </Container>
  )
}
