import { Breadcrumb, Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import './HttpBackend.module.css'
import HttpBackendForm from './HttpBackendForm'
import { FormEvent } from 'react'
import { toast } from 'react-toastify'
import { apiPost } from '../fetch'

export default function HttpBackendAdd() {
  const navigate = useNavigate()

  function onNewBackendSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    apiPost('/backends', {
      id: data['id'],
      host: data['host'],
      port: parseInt(data['port'] as string, 10),
      protocol: parseInt(data['protocol'] as string, 10),
      method: parseInt(data['method'] as string, 10),
      content_type: data['content_type'],
      path_prefix: data['path_prefix'],
      timeout_s: parseInt(data['timeout_s'] as string, 10),
      retry_interval_s: parseInt(data['retry_interval_s'] as string, 10),
      retry_count: parseInt(data['retry_count'] as string, 10),
    })
      .then(() => {
        toast(`HTTP Backend '${data['id']}' created`, { type: 'success' })
        navigate('/http')
      })
      .catch((err: Error) => {
        toast(`Error creating backend, ${err.message}`, { type: 'error' })
      })
  }

  return (
    <Container>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/http' }}>
            HTTP Backends
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/http-new` }} active>
            New HTTP Backend
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <Link to="/http">HTTP Backends</Link> /{' '}
            </span>{' '}
            Add new backend
          </h1>
          <p className="text-muted">
            A HTTP backend is the target HTTP server that will receive payloads extracted from MQTT packets. <br />
            Here you can define the delivery configuration and the packet filtering rules.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <form onSubmit={onNewBackendSubmit}>
            <HttpBackendForm />
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary">
                  Add new HTTP backend
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6">
          <dt>Backend ID</dt>
          <dd>
            Unique identifier for the backend. Use only lowercase characters, numbers, underscores, or hyphens:{' '}
            <code>a-z 0-9 _ -</code>
          </dd>

          <dt>Protocol, Host, Port</dt>
          <dd>The target HTTP server that will receive the extracted payloads.</dd>

          <dt>Method</dt>
          <dd>
            <p>The HTTP method to be used when sending the payload to the server.</p>
            <span className="text-warning">
              Note: GET method will try to fit the payload as a query parameter of the URL. Some servers only allow 2kB
              of URL length.
            </span>
          </dd>

          <dt>Path prefix</dt>
          <dd>
            Optional string that will be prepended to the path of the URL, before the <code>[MQTT Topic]</code>
            Unless you have specific requirements, it should not start with a slash, and it should end with a slash.
            Check the request template for confirmation of the final request URL.
          </dd>

          <dt>Content-Type header value</dt>
          <dd>
            The <code>Content-Type</code> HTTP header defines the format of the payload sent to the server. You should
            match the content type with the format of the payload sent by the device. If you are unsure, use{' '}
            <code>application/octet-stream</code> which is a generic binary format.
          </dd>

          <dt>Advanced: Timeout</dt>
          <dd>
            If the HTTP server doesn't respond within <code>timeout</code> seconds, the gateway will consider it a
            failed attempt, and it will retry the delivery of the payload according to the retry configuration.
          </dd>

          <dt>Advanced: Retry attempts and backoff</dt>
          <dd>
            <p>
              If the HTTP server is not reachable, the gateway will retry the delivery of the payload. It will try to
              deliver the payload <code>Retry attempts</code> times, waiting <code>Retry backoff</code> seconds to the
              power of the attempt number between each attempt (exponential backoff).
            </p>
            <span className="text-warning">Note: QoS 0 disables retries. The gateway will only make one attempt.</span>
          </dd>

          <dt>Request Template </dt>
          <dd>
            A preview of the HTTP method and URL of the requests to this backend. It contains the placeholder{' '}
            <code>[MQTT topic]</code> where the MQTT topic name will be inserted.
          </dd>

          <dt>Where are the subscription filtering rules?</dt>
          <dd>You can add filtering rules for this backend once it has been created.</dd>
        </div>
      </div>
    </Container>
  )
}
