import { Modal } from 'react-bootstrap'
import { Subscription } from '../types'
import React from 'react'
import { toast } from 'react-toastify'
import styles from './SubscriptionsModal.module.css'
import { apiDelete, apiGet, apiPost } from '../fetch'

async function deleteSubscription(sub: Subscription): Promise<void> {
  try {
    await apiDelete(`/subscription/${sub.id}`)
    toast(`Subscription ${sub.filter} deleted.`, { type: 'success' })
  } catch (err) {
    toast(`Error deleting subscription ${sub.filter}, ${(err as Error).message}`, {
      type: 'error',
    })
  }
}

async function updateSubscriptions(
  target: 'client' | 'backend',
  id: string,
  deletedSubscriptions: Subscription[],
  newSubscriptions: Subscription[]
) {
  await Promise.all(
    deletedSubscriptions.map((sub) => {
      return deleteSubscription(sub)
    })
  )
  if (newSubscriptions.length === 0) {
    return
  }
  try {
    await apiPost(`/subscriptions`, newSubscriptions)
    toast(`${newSubscriptions.length} new subscriptions created`, { type: 'success' })
  } catch (err) {
    toast(`Error updating subscriptions, ${(err as Error).message}`, { type: 'error' })
  }
}

export default function SubscriptionsModal(props: {
  subscriptions: Subscription[]
  backendId?: string
  clientId?: string
  show: boolean
  onHide: () => void
  onSubmit: () => void
}) {
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>(props.subscriptions)
  const [newSubscriptions, setNewSubscriptions] = React.useState<Subscription[]>([])
  const [selectIds, setSelectIds] = React.useState<string[]>([])
  const [subscriptionTemplate, setSubscriptionTemplate] = React.useState<Subscription>({
    id: '',
    filter: '',
    qos: 0,
    backend_id: '',
    client_id: '',
  })

  React.useEffect(() => {
    if (props.backendId === undefined && props.clientId === undefined) {
      return
    }

    setSubscriptions(props.subscriptions)
  }, [props.subscriptions, props.backendId, props.clientId])

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (props.backendId !== undefined) {
      const deletedSubscriptions = subscriptions.filter((sub) => sub.backend_id === '')
      updateSubscriptions('backend', props.backendId, deletedSubscriptions, newSubscriptions).then(() => {
        setSubscriptions([...subscriptions, ...newSubscriptions])
        setNewSubscriptions([])
        props.onSubmit()
      })
    } else if (props.clientId !== undefined) {
      const deletedSubscriptions = subscriptions.filter((sub) => sub.client_id === '')
      updateSubscriptions('client', props.clientId, deletedSubscriptions, newSubscriptions).then(() => {
        setSubscriptions([...subscriptions, ...newSubscriptions])
        setNewSubscriptions([])
        props.onSubmit()
      })
    }
  }

  function addNewFilter() {
    const clientMode = props.clientId !== undefined
    if (selectIds.length === 0) {
      apiGet(`/${clientMode ? 'backends' : 'clients'}/ids`)
        .then((data) => {
          setSelectIds(data)
          if (clientMode) {
            setSubscriptionTemplate({ ...subscriptionTemplate, backend_id: data[0], client_id: props.clientId! })
            setNewSubscriptions([
              ...newSubscriptions,
              { ...subscriptionTemplate, backend_id: data[0], client_id: props.clientId! },
            ])
          } else {
            setSubscriptionTemplate({ ...subscriptionTemplate, backend_id: props.backendId!, client_id: data[0] })
            setNewSubscriptions([
              ...newSubscriptions,
              { ...subscriptionTemplate, backend_id: props.backendId!, client_id: data[0] },
            ])
          }
        })
        .catch((err) => {
          toast(`Error fetching selector IDs, ${(err as Error).message}`, { type: 'error' })
        })
    } else {
      setNewSubscriptions([...newSubscriptions, { ...subscriptionTemplate }])
    }
  }

  function updateFilterRule(index: number, field: string, value: string | number) {
    const values = [...newSubscriptions]
    ;(values[index] as any)[field] = value
    setNewSubscriptions(values)
  }

  function onRemoveSubscription(index: number) {
    const values = [...subscriptions]
    if (props.backendId !== undefined) {
      values[index].backend_id = ''
    } else if (props.clientId !== undefined) {
      values[index].client_id = ''
    }
    setSubscriptions(values)
  }

  function onRemoveNewSubscription(index: number) {
    setNewSubscriptions(newSubscriptions.filter((_, i) => i !== index))
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit subscription filters</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <table>
            <thead>
              <tr>
                <th></th>
                {props.backendId !== undefined && <th>Client</th>}
                <th>Filter</th>
                <th>QoS</th>
                {props.clientId !== undefined && <th>Backend</th>}
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub, index) => (
                <tr
                  key={index}
                  className={
                    (props.backendId !== undefined && sub.backend_id === '') ||
                    (props.clientId !== undefined && sub.client_id === '')
                      ? styles.deleted
                      : ''
                  }
                >
                  <td>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => onRemoveSubscription(index)}>
                      -
                    </button>
                  </td>
                  {props.backendId !== undefined && <td>{sub.client_id}</td>}
                  <td>
                    <code>{sub.filter}</code>
                  </td>
                  <td>{sub.qos}</td>
                  {props.clientId !== undefined && <td>{sub.backend_id}</td>}
                </tr>
              ))}
              {newSubscriptions.map((sub, index) => (
                <tr key={index}>
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => onRemoveNewSubscription(index)}
                    >
                      -
                    </button>
                  </td>
                  {props.backendId !== undefined ? (
                    <td>
                      <select
                        className="form-select"
                        onChange={(e) => updateFilterRule(index, 'client_id', e.target.value)}
                      >
                        {selectIds.map((clientId) => (
                          <option key={clientId} value={clientId}>
                            {clientId}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : (
                    ''
                  )}
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={sub.filter}
                      onChange={(e) => updateFilterRule(index, 'filter', e.target.value)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={sub.qos.toString(10)}
                      onChange={(e) => updateFilterRule(index, 'qos', parseInt(e.target.value, 10))}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </td>
                  {props.clientId !== undefined ? (
                    <td>
                      <select
                        className="form-select"
                        onChange={(e) => updateFilterRule(index, 'backend_id', e.target.value)}
                      >
                        {selectIds.map((backendId) => (
                          <option key={backendId} value={backendId}>
                            {backendId}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ))}
              <tr>
                <td colSpan={4} className="text-center">
                  <button type="button" className="btn btn-secondary" onClick={addNewFilter}>
                    + Add another subscription
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={props.onHide}>
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Save changes
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
