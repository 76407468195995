import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb, Container } from 'react-bootstrap'

import styles from './MqttEventObserver.module.css'

type RealtimeEvent = {
  d: 'rx' | 'tx'
  q: 0 | 1 | 2
  t: string
  p: string
  ts: Date // added in post-processing
}

export default function MqttEventObserver() {
  const { id } = useParams()
  const [events, setEvents] = useState<RealtimeEvent[]>([])
  useEffect(() => {
    if (id === undefined) {
      return
    }

    const eventSource = new EventSource(`/api/client/${id}/events`)
    // eventSource.onmessage = function (event) {
    //   console.log(event)
    //   const data = JSON.parse(event.data)
    //   setEvents((events) => [...events, data])
    // }
    eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      data.p = atob(data.p)
      data.ts = new Date()
      setEvents((events) => [...events, data])
    })
    eventSource.addEventListener('error', (event) => {
      toast('Connection lost, reconnecting...', { type: 'error' })
    })
    eventSource.addEventListener('close', () => {
      toast('Connection closed. Reload to re-open', { type: 'error' })
    })

    return () => {
      eventSource.close()
    }
  }, [id])

  return (
    <Container fluid>
      <header>
        <Breadcrumb>
          <Breadcrumb.Item active>Avoguard Gateway</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/mqtt' }}>
            MQTT Clients
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/mqtt/${id}` }}>
            {id}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/mqtt/${id}/events` }} active>
            Real-time event tap
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>

      <div className="row">
        <div className="col">
          <h1>
            <span className="text-muted">
              <Link to="/mqtt">MQTT Clients</Link> / <Link to={`/mqtt/{id}`}>{id}</Link> /{' '}
            </span>
            Real-time event tap
          </h1>
          <p className="text-muted">
            A real-time stream of data flow to and from the MQTT client. This is a read-only view.
          </p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <table className={styles.events}>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Direction</th>
                <th>QoS</th>
                <th>Topic</th>
                <th>Payload</th>
              </tr>
            </thead>
            <tbody>
              {events.length === 0 ? (
                <tr>
                  <td colSpan={4}>Waiting for events ...</td>
                </tr>
              ) : (
                events.map((event, i) => (
                  <tr key={i}>
                    <td>
                      {new Intl.DateTimeFormat(undefined, {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        fractionalSecondDigits: 3,
                      }).format(event.ts)}
                    </td>
                    {/* <td>{intlFormat(event.ts, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</td> */}
                    <td>{event.d === 'rx' ? 'IN' : 'OUT'}</td>
                    <td>{event.q}</td>
                    <td>
                      <code>{event.t}</code>
                    </td>
                    <td>{event.p}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  )
}
